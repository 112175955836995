import { default as _91slug_93n0L1WH95EkMeta } from "/home/forge/modaoutlet.com.tr/pages/[slug].vue?macro=true";
import { default as addresshWxRQfPDzhMeta } from "/home/forge/modaoutlet.com.tr/pages/account/address.vue?macro=true";
import { default as _91id_935hCVPg7zgzMeta } from "/home/forge/modaoutlet.com.tr/pages/account/orders/[id].vue?macro=true";
import { default as _91id_9397NLO5fYtjMeta } from "/home/forge/modaoutlet.com.tr/pages/account/orders/cancel/[id].vue?macro=true";
import { default as indexenUnOj4E6gMeta } from "/home/forge/modaoutlet.com.tr/pages/account/orders/index.vue?macro=true";
import { default as reviewejxJ3CbM7XMeta } from "/home/forge/modaoutlet.com.tr/pages/account/review.vue?macro=true";
import { default as _91id_93NBUwk462djMeta } from "/home/forge/modaoutlet.com.tr/pages/account/statement/detail/[id].vue?macro=true";
import { default as indexKSM3cG4wuIMeta } from "/home/forge/modaoutlet.com.tr/pages/account/statement/index.vue?macro=true";
import { default as userEeNKXMyZQwMeta } from "/home/forge/modaoutlet.com.tr/pages/account/user.vue?macro=true";
import { default as wishlistTy18re5YLOMeta } from "/home/forge/modaoutlet.com.tr/pages/account/wishlist.vue?macro=true";
import { default as cartxKruQ5X1y2Meta } from "/home/forge/modaoutlet.com.tr/pages/cart.vue?macro=true";
import { default as indexf4yatLEg26Meta } from "/home/forge/modaoutlet.com.tr/pages/checkout/index.vue?macro=true";
import { default as paymentCmZg4sGnYCMeta } from "/home/forge/modaoutlet.com.tr/pages/checkout/payment.vue?macro=true";
import { default as summary_46clientLLeDyYkEOSMeta } from "/home/forge/modaoutlet.com.tr/pages/checkout/summary.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/home/forge/modaoutlet.com.tr/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as requestCM8a7B9taPMeta } from "/home/forge/modaoutlet.com.tr/pages/customer/request.vue?macro=true";
import { default as successZtCNNxe5OUMeta } from "/home/forge/modaoutlet.com.tr/pages/customer/success.vue?macro=true";
import { default as iletisimIo0izn0dOEMeta } from "/home/forge/modaoutlet.com.tr/pages/iletisim.vue?macro=true";
import { default as indexUapxVvMGexMeta } from "/home/forge/modaoutlet.com.tr/pages/index.vue?macro=true";
import { default as loginmj8gPHO3qVMeta } from "/home/forge/modaoutlet.com.tr/pages/login.vue?macro=true";
import { default as offerMo1v65zL4lMeta } from "/home/forge/modaoutlet.com.tr/pages/offer.vue?macro=true";
import { default as status465uX8yyWrMeta } from "/home/forge/modaoutlet.com.tr/pages/order/status.vue?macro=true";
import { default as track7HccaN9LpFMeta } from "/home/forge/modaoutlet.com.tr/pages/order/track.vue?macro=true";
import { default as forgotczTLuiETULMeta } from "/home/forge/modaoutlet.com.tr/pages/password/forgot.vue?macro=true";
import { default as reset6n6twSpUWMMeta } from "/home/forge/modaoutlet.com.tr/pages/password/reset.vue?macro=true";
import { default as payment_45notificationl8wwuYJvogMeta } from "/home/forge/modaoutlet.com.tr/pages/payment-notification.vue?macro=true";
import { default as summaryDzz5uWF5YGMeta } from "/home/forge/modaoutlet.com.tr/pages/payment/summary.vue?macro=true";
import { default as registerhCnMRCkqM7Meta } from "/home/forge/modaoutlet.com.tr/pages/register.vue?macro=true";
import { default as searchS98FJhi0FQMeta } from "/home/forge/modaoutlet.com.tr/pages/search.vue?macro=true";
import { default as locationsJyVAJTOkyIMeta } from "/home/forge/modaoutlet.com.tr/modules/locationsPage/pages/locations.vue?macro=true";
export default [
  {
    name: "slug___tr",
    path: "/:slug()",
    meta: _91slug_93n0L1WH95EkMeta || {},
    component: () => import("/home/forge/modaoutlet.com.tr/pages/[slug].vue")
  },
  {
    name: "account-address___tr",
    path: "/account/address",
    meta: addresshWxRQfPDzhMeta || {},
    component: () => import("/home/forge/modaoutlet.com.tr/pages/account/address.vue")
  },
  {
    name: "account-orders-id___tr",
    path: "/account/orders/:id()",
    meta: _91id_935hCVPg7zgzMeta || {},
    component: () => import("/home/forge/modaoutlet.com.tr/pages/account/orders/[id].vue")
  },
  {
    name: "account-orders-cancel-id___tr",
    path: "/account/orders/cancel/:id()",
    meta: _91id_9397NLO5fYtjMeta || {},
    component: () => import("/home/forge/modaoutlet.com.tr/pages/account/orders/cancel/[id].vue")
  },
  {
    name: "account-orders___tr",
    path: "/account/orders",
    meta: indexenUnOj4E6gMeta || {},
    component: () => import("/home/forge/modaoutlet.com.tr/pages/account/orders/index.vue")
  },
  {
    name: "account-review___tr",
    path: "/account/review",
    meta: reviewejxJ3CbM7XMeta || {},
    component: () => import("/home/forge/modaoutlet.com.tr/pages/account/review.vue")
  },
  {
    name: "account-statement-detail-id___tr",
    path: "/account/statement/detail/:id()",
    meta: _91id_93NBUwk462djMeta || {},
    component: () => import("/home/forge/modaoutlet.com.tr/pages/account/statement/detail/[id].vue")
  },
  {
    name: "account-statement___tr",
    path: "/account/statement",
    meta: indexKSM3cG4wuIMeta || {},
    component: () => import("/home/forge/modaoutlet.com.tr/pages/account/statement/index.vue")
  },
  {
    name: "account-user___tr",
    path: "/account/user",
    meta: userEeNKXMyZQwMeta || {},
    component: () => import("/home/forge/modaoutlet.com.tr/pages/account/user.vue")
  },
  {
    name: "account-wishlist___tr",
    path: "/account/wishlist",
    meta: wishlistTy18re5YLOMeta || {},
    component: () => import("/home/forge/modaoutlet.com.tr/pages/account/wishlist.vue")
  },
  {
    name: "cart___tr",
    path: "/cart",
    component: () => import("/home/forge/modaoutlet.com.tr/pages/cart.vue")
  },
  {
    name: "checkout___tr",
    path: "/checkout",
    meta: indexf4yatLEg26Meta || {},
    component: () => import("/home/forge/modaoutlet.com.tr/pages/checkout/index.vue")
  },
  {
    name: "checkout-payment___tr",
    path: "/checkout/payment",
    meta: paymentCmZg4sGnYCMeta || {},
    component: () => import("/home/forge/modaoutlet.com.tr/pages/checkout/payment.vue")
  },
  {
    name: "checkout-summary___tr",
    path: "/checkout/summary",
    component: () => createClientPage(() => import("/home/forge/modaoutlet.com.tr/pages/checkout/summary.client.vue"))
  },
  {
    name: "customer-request___tr",
    path: "/customer/request",
    component: () => import("/home/forge/modaoutlet.com.tr/pages/customer/request.vue")
  },
  {
    name: "customer-success___tr",
    path: "/customer/success",
    component: () => import("/home/forge/modaoutlet.com.tr/pages/customer/success.vue")
  },
  {
    name: "iletisim___tr",
    path: "/iletisim",
    component: () => import("/home/forge/modaoutlet.com.tr/pages/iletisim.vue")
  },
  {
    name: "index___tr",
    path: "/",
    meta: indexUapxVvMGexMeta || {},
    component: () => import("/home/forge/modaoutlet.com.tr/pages/index.vue")
  },
  {
    name: "login___tr",
    path: "/login",
    meta: loginmj8gPHO3qVMeta || {},
    component: () => import("/home/forge/modaoutlet.com.tr/pages/login.vue")
  },
  {
    name: "offer___tr",
    path: "/offer",
    meta: offerMo1v65zL4lMeta || {},
    component: () => import("/home/forge/modaoutlet.com.tr/pages/offer.vue")
  },
  {
    name: "order-status___tr",
    path: "/order/status",
    component: () => import("/home/forge/modaoutlet.com.tr/pages/order/status.vue")
  },
  {
    name: "order-track___tr",
    path: "/order/track",
    component: () => import("/home/forge/modaoutlet.com.tr/pages/order/track.vue")
  },
  {
    name: "password-forgot___tr",
    path: "/password/forgot",
    meta: forgotczTLuiETULMeta || {},
    component: () => import("/home/forge/modaoutlet.com.tr/pages/password/forgot.vue")
  },
  {
    name: "password-reset___tr",
    path: "/password/reset",
    meta: reset6n6twSpUWMMeta || {},
    component: () => import("/home/forge/modaoutlet.com.tr/pages/password/reset.vue")
  },
  {
    name: "payment-notification___tr",
    path: "/payment-notification",
    component: () => import("/home/forge/modaoutlet.com.tr/pages/payment-notification.vue")
  },
  {
    name: "payment-summary___tr",
    path: "/payment/summary",
    component: () => import("/home/forge/modaoutlet.com.tr/pages/payment/summary.vue")
  },
  {
    name: "register___tr",
    path: "/register",
    meta: registerhCnMRCkqM7Meta || {},
    component: () => import("/home/forge/modaoutlet.com.tr/pages/register.vue")
  },
  {
    name: "search___tr",
    path: "/search",
    meta: searchS98FJhi0FQMeta || {},
    component: () => import("/home/forge/modaoutlet.com.tr/pages/search.vue")
  },
  {
    name: "contact___tr",
    path: "/magazalarimiz",
    component: () => import("/home/forge/modaoutlet.com.tr/modules/locationsPage/pages/locations.vue")
  }
]